//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//

// ========================================================================
// Component: Navigation
// ========================================================================


.topnav {
	display:inline-block;
	width:auto;

	ul {
		display:inline-block;
		width:auto;
		list-style:none;
		padding:0;

		li {
			display:inline-block;
			width:auto;
			margin-right:20px;

			a {
				display:block;
				position:relative;
				color: #000000;
				font-family: $black;
				font-size: 14px;

				span {
					position:relative;
					z-index:9;
				}
				&:before {
					position:absolute;
					content:'';
					left:0;
					bottom:10px;
					width:100%;
					height:7px;
					background:$yellow;
					opacity:0;
					transform:translate3d(-150%, 0, 0);
					transition:.6s;
				}
				&:hover:before {
					opacity:1;
					transform:translate3d(0, 0, 0);
				}
			}
		}
	}
	.btn {
		margin-left:30px;
		margin-right:20px;
	}
}
