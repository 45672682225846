//
// Name:            Header
//
// Description:     Contains all header styles
//

// ========================================================================
// Component: Header
// ========================================================================


#header {
	position:fixed;
	width:100%;
	background:#fff;
	padding:18px 0;
	z-index:10;

	.col-xs-6.end-xs {
		text-align:right;
	}
	.logo {
		display:inline-block;
		width:auto;
		margin-left:20px;

		.dots {
			display:inline-block;
			width:auto;
			margin-right:20px;

			span {
				display:inline-block;
				width:13px;
				height:13px;
				border-radius:13px;
				margin-right:13px;

				&.red {
					background:$red;
				}
				&.orange {
					background:$orange;
				}
				&.yellow {
					background:$yellow;
				}
				&.green {
					background:$green;
				}
				&.blue {
					background:$blue;
				}
			}
		}
		.name {
			display:inline-block;
			width:auto;
			color: #000000;
			font-family: $proxima_bold;
			font-size: 24px;
		}
	}
}
.hamburger {
	display:none;
}
.mobile-menu {
	display:none;
	position:absolute;
	top:63px;
	left:0;
	width:100%;
	height:100vh;
	z-index:11;
	background:#fff;
	text-align:center;
	padding:40px 0;

	ul {
		list-style:none;
		padding:0;
		margin:0;

		li {
			display:inline-block;
			width:100%;
			margin:20px 0;

			a {
				font-size:24px;
				display:block;
				width:100%;
				font-family:$heavy;
			}
		}
	}
}
