//
// Name:            Layout
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Base
// ========================================================================


.shell {
	//overflow-x:hidden;
}

.main {
	//padding:0;
	//position:relative;
}

.section {
	overflow:hidden;
}

.row {
	margin-left: -.5rem;
	margin-right: -.5rem;

	& + & {
		margin-top:20px;
	}

	&,
	> [class*="col-"] {
		position: relative;
	}

	> [class*="col-"] {
		padding-left: .5rem;
		padding-right: .5rem;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.row > [class*="col-"] {
		flex-basis: 100%;
    	max-width: 100%;
    	margin-bottom:10px;

    	&:first-child {
    		margin-top:10px;
    	}
	}
	.first-xs {
		order:1;
		-ms-flex-order: 1;
	}
	.last-xs {
		order:0;
		-ms-flex-order: 0;
	}
}