//
// Name:            Fonts
//
// Description:     Contains all fonts
//


// ========================================================================
// Component: Fonts
// ========================================================================


@font-face {
    font-family: 'Avenir Black';
    src: url('../fonts/Avenir-Black.eot');
    src: url('../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Avenir-Black.woff') format('woff'),
        url('../fonts/Avenir-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../fonts/Avenir-Book.eot');
    src: url('../fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Avenir-Book.woff') format('woff'),
        url('../fonts/Avenir-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Heavy';
    src: url('../fonts/Avenir-Heavy.eot');
    src: url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Avenir-Heavy.woff') format('woff'),
        url('../fonts/Avenir-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Light';
    src: url('../fonts/Avenir-Light.eot');
    src: url('../fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Avenir-Light.woff') format('woff'),
        url('../fonts/Avenir-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Medium';
    src: url('../fonts/Avenir-Medium.eot');
    src: url('../fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Avenir-Medium.woff') format('woff'),
        url('../fonts/Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/proximanova-regular-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('../fonts/proximanova-bold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
