//
// Name:            Buttons
//
// Description:     Contains all button styles
//

// ========================================================================
// Component
// ========================================================================


.btn {
	display:inline-block;
	width:auto;
	padding:13px 40px;
	font-size: 14px;
	text-align:center;
	font-family:$proxima;
	transition:.3s;

	&.black-btn {
		background:#000;
		color:#fff;
	}
	&:hover {
		background:$green;
		color:#000;
	}
}
