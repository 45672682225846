//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//

// ========================================================================
// Component
// ========================================================================


body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-size: 18px;
	line-height: 26px;
}

a {
	@include transition(.6s);
}

video::-webkit-media-controls {
	display: none !important;
}
