//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================

// ========================================================================
// Component: Variables
// ========================================================================


// Fonts
$black: 'Avenir Black';
$book: 'Avenir Book';
$heavy: 'Avenir Heavy';
$light: 'Avenir Light';
$medium: 'Avenir Medium';
$proxima: 'proxima_nova_rgregular';
$proxima_bold: 'proxima_nova_rgbold';

// Colors
$red: #f85034;
$orange: #ff9720;
$yellow: #ffe328;
$green: #8ffa22;
$blue: #48e6ff;

// Forms
$form-field-border-radius:0;
$form-field-background:transparent;
$form-field-border:1px solid #979797;
$form-field-font-size:16px;
$form-field-color:#fff;
$form-field-padding:0 20px;
$form-field-height:44px;
